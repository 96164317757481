// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-code-of-conduct-mdx": () => import("./../../../src/pages/code-of-conduct.mdx" /* webpackChunkName: "component---src-pages-code-of-conduct-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-mdx": () => import("./../../../src/pages/resources.mdx" /* webpackChunkName: "component---src-pages-resources-mdx" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-templates-md-software-js": () => import("./../../../src/templates/mdSoftware.js" /* webpackChunkName: "component---src-templates-md-software-js" */)
}

